// React
import React, { Component } from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import { AiOutlineClose } from 'react-icons/ai';
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: '',
      menu: window.location.pathname,
    };
    this.menuTrigger = this.menuTrigger.bind(this);
    this.closeMenuTrigger = this.closeMenuTrigger.bind(this);
    window.addEventListener('load', function () {
      console.log('All assets are loaded');
    });
  }
  menuTrigger() {
    document.querySelector('.header-wrapper').classList.toggle('menu-open');
  }

  closeMenuTrigger() {
    document.querySelector('.header-wrapper').classList.remove('menu-open');
  }

  handleChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    window.location.href = `${process.env.REACT_APP_MAIN_DOMAIN}/search/?search=${this.state.searchQuery}`;
  };
  handleLogOut = () => {
    localStorage.removeItem('token');
    window.location.href = `${process.env.REACT_APP_SUB_DOMAIN}/users/logout/${encodeURIComponent(
      window.location.href,
    )}`;
  };
  handleChangeLanguage = () => {
    if (localStorage.getItem('language') == 'vi-VN') {
      localStorage.removeItem('language');
      localStorage.setItem('language', 'en-US');
      window.location.href = `${process.env.REACT_APP_SUB_DOMAIN}/setlanguage/${encodeURIComponent(
        window.location.href,
      )}/en-US`;
    } else {
      localStorage.removeItem('language');
      localStorage.setItem('language', 'vi-VN');
      window.location.href = `${process.env.REACT_APP_SUB_DOMAIN}/setlanguage/${encodeURIComponent(
        window.location.href,
      )}/vi-VN`;
    }
  };
  render() {
    if (!localStorage.getItem('language')) {
      localStorage.setItem('language', 'en-US');
      window.location.href = `${process.env.REACT_APP_SUB_DOMAIN}/setlanguage/${encodeURIComponent(
        window.location.href,
      )}/en-US`;
    }
    var elements = document.querySelectorAll('.has-droupdown > a');
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement.querySelector('.submenu').classList.toggle('active');
          this.classList.toggle('open');
        };
      }
    }
    const { logo } = this.props;
    const Menu = [
      {
        to: '/',
        title: localStorage.getItem('language') === 'en-US' ? 'Home' : 'Trang chủ',
        domain: `${process.env.REACT_APP_MAIN_DOMAIN}`,
      },
      {
        to: '/about',
        title: localStorage.getItem('language') === 'en-US' ? 'About Us' : 'Giới thiệu',
        domain: `${process.env.REACT_APP_MAIN_DOMAIN}`,
      },
      {
        to: '/business-representative',
        title: localStorage.getItem('language') === 'en-US' ? 'Business Representative' : 'Đại diện kinh doanh',
        domain: `${process.env.REACT_APP_MAIN_DOMAIN}`,
      },
      {
        to: '/export-to-au',
        title: localStorage.getItem('language') === 'en-US' ? 'Export To Australia' : 'Xuất khẩu đến Úc',
        domain: `${process.env.REACT_APP_MAIN_DOMAIN}`,
      },
      {
        to: '/export-to-vn',
        title: localStorage.getItem('language') === 'en-US' ? 'Export To Viet Nam' : 'Xuất khẩu đến Việt Nam',
        domain: `${process.env.REACT_APP_MAIN_DOMAIN}`,
      },
      {
        to: `/forum`,
        title: localStorage.getItem('language') === 'en-US' ? 'Forum' : 'Diễn đàn',
        domain: `${process.env.REACT_APP_SUB_DOMAIN}`,
      },
      {
        to: `/consulting`,
        title: localStorage.getItem('language') === 'en-US' ? 'Consulting' : 'Tư vấn',
        domain: `${process.env.REACT_APP_SUB_DOMAIN}`,
      },
    ];
    let logoUrl = <img src={logo} width={100} height={80} alt="Digital Agency" />;
    const url = window.location.href;
    const encodedId = encodeURIComponent(url);
    return (
      <header className={`header-area header-style-two header--fixed`}>
        <div className="header-wrapper">
          <div className="header-left d-flex align-items-center">
            <div className="logo" style={{width:"100px",height:"80px"}}>
              <a href="/" style={{width:"100px",height:"80px",display:"block"}}>{logoUrl}</a>
            </div>
            <nav className="mainmenunav ml--40">
              <ul className="mainmenu">
                {Menu.map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={item.domain + item.to} className={item.to === this.state.menu ? 'active' : undefined}>
                        {item.title}
                      </a>
                    </li>
                  );
                })}
                <li>
                  <form
                    className="mainmenu__form"
                    style={{
                      paddingTop: '1.02rem',
                    }}
                    onSubmit={this.handleSubmit}
                  >
                    <input
                      style={{
                        width: '8rem',
                      }}
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={this.state.searchQuery}
                      onChange={this.handleChange}
                    />
                  </form>
                </li>
                {localStorage.getItem('token') ? (
                  <li
                    className="nav-item login-btn-responsive "
                    style={{
                      listStyle: 'none',
                    }}
                  >
                    <span className="nav-item close-btn">
                      <div id="mobile" onClick={this.handleClick}>
                        <i id="bar">
                          {/* {this.state.clicked ? <AiOutlineClose /> : <AiOutlineMenu />} */}
                          {this.state.clicked && <AiOutlineClose />}
                        </i>
                      </div>
                    </span>
                    <a onClick={this.handleLogOut} style={{ marginTop: '-4px' }}>
                      <button type="button" className="btn btn-outline-dark">
                        <b>{localStorage.getItem('language') === 'en-US' ? 'Log Out' : 'Đăng Xuất'}</b>
                      </button>
                    </a>
                    <div
                      className="login-btn-responsive"
                      onClick={this.handleChangeLanguage}
                      style={{ cursor: 'pointer' }}
                    >
                      <img
                        src={`${process.env.REACT_APP_MAIN_DOMAIN}/assets/images/${
                          localStorage.getItem('language') == 'vi-VN' ? 'au' : 'vi'
                        }.png`}
                        style={{ height: '40px', width: '64px', borderRadius: '4px', marginTop: '20px' }}
                      ></img>
                    </div>
                  </li>
                ) : (
                  <li className="nav-item login-btn-responsive">
                    <span className="nav-item close-btn">
                      <div id="mobile" onClick={this.handleClick}>
                        <i id="bar">
                          {/* {this.state.clicked ? <AiOutlineClose /> : <AiOutlineMenu />} */}
                          {this.state.clicked && <AiOutlineClose />}
                        </i>
                      </div>
                    </span>
                    <a
                      href={`${process.env.REACT_APP_SUB_DOMAIN}/users/login/${encodedId}`}
                      style={{ marginTop: '-4px' }}
                    >
                      <button type="button" className="btn btn-outline-dark">
                        <b>{localStorage.getItem('language') === 'en-US' ? 'Sign In' : 'Đăng Nhập'}</b>
                      </button>
                    </a>
                    <div
                      className="login-btn-responsive"
                      onClick={this.handleChangeLanguage}
                      style={{ cursor: 'pointer' }}
                    >
                      <img
                        src={`${process.env.REACT_APP_MAIN_DOMAIN}/assets/images/${
                          localStorage.getItem('language') == 'vi-VN' ? 'au' : 'vi'
                        }.png`}
                        style={{ height: '40px', width: '64px', borderRadius: '4px', marginTop: '20px' }}
                      ></img>
                    </div>
                  </li>
                )}
              </ul>
              <ul className="mainmenu special">
                {localStorage.getItem('token') ? (
                  <>
                    <li onClick={this.handleChangeLanguage} style={{ cursor: 'pointer' }}>
                      <img
                        src={`${process.env.REACT_APP_MAIN_DOMAIN}/assets/images/${
                          localStorage.getItem('language') == 'vi-VN' ? 'au' : 'vi'
                        }.png`}
                        style={{ height: '32px', width: '48px', borderRadius: '4px' }}
                      ></img>
                    </li>
                    <li
                      className="nav-item login-btn-responsive special"
                      style={{
                        listStyle: 'none',
                      }}
                    >
                      <span className="nav-item close-btn">
                        <div id="mobile" onClick={this.handleClick}>
                          <i id="bar">
                            {/* {this.state.clicked ? <AiOutlineClose /> : <AiOutlineMenu />} */}
                            {this.state.clicked && <AiOutlineClose />}
                          </i>
                        </div>
                      </span>
                      <a onClick={this.handleLogOut} style={{ marginTop: '-4px' }}>
                        <button type="button" className="btn btn-outline-dark">
                          <b>{localStorage.getItem('language') === 'en-US' ? 'Log Out' : 'Đăng Xuất'}</b>
                        </button>
                      </a>
                    </li>
                  </>
                ) : (
                  <>
                    <li onClick={this.handleChangeLanguage} style={{ cursor: 'pointer' }}>
                      <img
                        src={`${process.env.REACT_APP_MAIN_DOMAIN}/assets/images/${
                          localStorage.getItem('language') == 'vi-VN' ? 'au' : 'vi'
                        }.png`}
                        style={{ height: '32px', width: '48px', borderRadius: '4px' }}
                      ></img>
                    </li>
                    <li className="nav-item login-btn-responsive special">
                      <span className="nav-item close-btn">
                        <div id="mobile" onClick={this.handleClick}>
                          <i id="bar">{this.state.clicked && <AiOutlineClose />}</i>
                        </div>
                      </span>
                      <a
                        href={`${process.env.REACT_APP_SUB_DOMAIN}/users/login/${encodedId}`}
                        style={{ marginTop: '-4px' }}
                      >
                        <button type="button" className="btn btn-outline-dark">
                          <b>{localStorage.getItem('language') === 'en-US' ? 'Sign In' : 'Đăng Nhập'}</b>
                        </button>
                      </a>
                    </li>
                  </>
                )}
              </ul>
            </nav>
          </div>
          <div className="header-right">
            {/* Start Humberger Menu  */}
            <div className="humberger-menu pl--20">
              <span onClick={this.menuTrigger} className="menutrigger text-white">
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu">
              <span onClick={this.closeMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
