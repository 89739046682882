import ForumPostSlider from './ForumPostSlider';
import React from 'react';
import Slide from './Slide';
import { getPosts } from '../../api/getPosts.js';
import PageIntroduction from './PageIntroduction';

function Heading(props) {
  const { title, subtitle } = props;
  return (
    <div className="row">
      <div className="section-title service-style--3 text-center mb--30 mb_sm--0">
        <h2 className="title newTitle">{title}</h2>
        <p className="newSubtitle">{subtitle}</p>
      </div>
    </div>
  );
}
function ForumSlide(props) {
  const {
    content: { title, subtitle },
  } = props;

  const [posts, setPosts] = React.useState([]);

  React.useEffect(() => {
      getPosts()
      .then((posts) => {
        const items = posts.map((post) => ({
          title: post.title,
          subtitle: post.description,
          link: `${process.env.REACT_APP_SUB_DOMAIN}/post/${post.id}`,
        }));
        console.log(items);
        setPosts(items);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const content = {
    title: title,
    subtitle: subtitle,
    slides: posts,
  };

  return (
    <PageIntroduction content={content}/>
  );
}

export default ForumSlide;
