import React from 'react';

class Categories extends React.Component {
  constructor(props) {
    super(props);
  }
  handleCategorySelect = (event) => {
    event.preventDefault();
    const selectedValue = event.target.value;
    let check=false;
    this.props.categories.map((cat) => 
    {
      if (cat.id === selectedValue) 
      {
        check=true;
        return this.props.setSelectedCategory(cat.id);
      }
    });
    if (!check)    return this.props.setSelectedCategory('');
  
  };
  render() {
    return window.innerWidth>=767?(
      <div>
        <ul className={`category ${this.props.style} categorystyle--2`}>
          <li
            onClick={() => this.props.setSelectedCategory('')}
            className={this.props.selectedCategory === '' && 'active'}
            style={{lineHeight:"20px"}}
          >
            All
          </li>
          {this.props.categories.map((cat) => {
            return (
              <li
                key={cat.id}
                onClick={() => this.props.setSelectedCategory(cat.id)}
                className={this.props.selectedCategory === cat.id && 'active'}
                style={{lineHeight:"24px",visibility:`${cat.name==="NON FOODS" || cat.name == "Sản phẩm không phải thực phẩm"?"hidden":""}`}}
              >
                {cat.name}
              </li>
            );
          })}
        </ul>
      </div>
    ):(
      <div className='mb-4 mt-4'>
        <select
        className={`category ${this.props.style} categorystyle--2`}
        onChange={this.handleCategorySelect}
      >
        <option value="" className={this.props.selectedCategory === '' && 'active'}>All</option>
        {this.props.categories.map((cat) => (
          <option key={cat.id} value={cat.id} className={this.props.selectedCategory === cat.id && 'active'}>
            {cat.name!="NON FOODS" && cat.name}
          </option>
        ))}
      </select>
      </div>
    );
  }
}

export default Categories;
