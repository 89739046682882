// react
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Pagination from "../common/Pagination";
import { client } from "../../../client";
// components
import Product from "./Product";
import Categories from "../common/Categories";

class DetailProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: "",
      currentPage: 1,
      productData: {
        loading: true,
        error: null,
        products: [],
        total: 0,
        category:[],
        allproducts:[]
      },
    };
  }

  componentDidMount() {
    Promise.all([
      client
      .getEntries({
        content_type: "product",
        "fields.type": this.props.productsType,
        limit:200,
        locale: localStorage.getItem('language')
      }),
      client.getEntries({
        content_type: "category",
      })
    ])    
      .then(([rawProductData,category]) => {
        let AllProductsSortByCate=[];
        let cates=[];
        for (let i = 0; i < this.props.categories.length; i++) {
          cates.push([]);
        }
        const products = rawProductData.items.map((product) => {
          const { sys, fields } = product;
          const categories = fields.categories.map(
            (category) => category.fields.name
          );
          let cate=this.props.categories;
          for (let i = 0; i < cate.length; i++) {
            if (cate[i].name==categories[0])
            cates[i].push({
              id: sys.id,
              imageUrl: fields.image.fields.file.url,
              name: fields.name,
              categories: categories,
            })
          }
          return {
            id: sys.id,
            imageUrl: fields.image.fields.file.url,
            name: fields.name,
            categories: categories,
          };
        });
        for (let i = 0; i < this.props.categories.length; i++) 
          AllProductsSortByCate=[...AllProductsSortByCate,...cates[i]];
        
        const categories= category.items.flatMap(element => 
          {
            return {name:element.fields.name,id:element.sys.id};
          });
        this.setState({
          productData: {
            loading: false,
            error: null,
            products: products,
            total:AllProductsSortByCate.length,
            category:categories,
            allproducts:AllProductsSortByCate
          },
        });
      })
      .catch((error) =>
        this.setState({
          product: {
            error: error,
          },
        })
      );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.currentPage !== prevState.currentPage ||
      this.state.selectedCategory !== prevState.selectedCategory
    ) {
      client
        .getEntries({
          content_type: "product",
          "fields.type": this.props.productsType,
          limit: this.props.item,
          skip: (this.state.currentPage - 1) * this.props.item,
          ...(this.state.selectedCategory && {
            links_to_entry: this.state.selectedCategory,
          }),          
          locale: localStorage.getItem('language')
        })
        .then((rawProductData) => {
          const products = rawProductData.items.map((product) => {
            const { sys, fields } = product;
            const categories = fields.categories.map(
              (category) => category.fields.name
            );
            return {
              id: sys.id,
              imageUrl: fields.image.fields.file.url,
              name: fields.name,
              categories: categories,
            };
          });
          this.setState({
            productData: {
              ...this.state.productData,
              loading: false,
              error: null,
              products: products,
              total: this.state.selectedCategory==""?this.state.productData.allproducts.length:rawProductData.total,
            },
            currentPage: this.state.selectedCategory !== prevState.selectedCategory ? 1 : this.state.currentPage,
          });
        })
        .catch((error) =>
          this.setState({
            product: {
              error: error,
            },
          })
        );
    }
  }

  render() {
    const { column } = this.props;
    let { loading, error, products, total} = this.state.productData;
    products=this.state.selectedCategory === ""?this.state.productData.allproducts.slice(this.props.item*(this.state.currentPage-1),this.props.item*this.state.currentPage):products;
    const isDesktop = window.matchMedia("(min-width: 992px)");
    let num = 0;
    if (isDesktop.matches) {
      num = 3;
    }
    else num=2;
    return (
      <div className="row">
        <div className="col-md-3">
          <Categories
            categories={this.props.categories}
            selectedCategory={this.state.selectedCategory}
            setSelectedCategory={(selectedCategory) =>
              this.setState({
                selectedCategory: selectedCategory,
              })
            }
            style={this.props.categoryStyle}
          />
        </div>
        {loading ? (
          error ? (
            <div className="col-md-9 col-12 text-center">
              <p>
                <strong>Sorry, something went wrong</strong>
              </p>
            </div>
          ) : (
            <div className="col-12 text-center">
              <p>
                <strong>Loading...</strong>
              </p>
            </div>
          )
        ) : products.length > 0 ? (
          <div className="col-sm-9 m-auto col-12">
            <div className="row">
              {products.map((value, index) => {
                return (
                  <div className={`${column}`} key={index}>
                    <Link
                      to={`/product/${value.id}`}
                      className="d-flex align-middle"
                    >
                      <Product product={value} cate={this.state.productData.category}/>
                    </Link>
                  </div>
                );
              })}
            </div>
            <div className="row mt--20">
              <div className="col-lg-12">
                <Pagination
                  currentPage={this.state.currentPage}
                  setCurrentPage={(currentPage) => {
                    this.setState({
                      currentPage: currentPage,
                    });
                  }}
                  totalPage={Math.ceil(total / this.props.item)}
                  limitPage={4}
                  style={this.props.paginationStyle}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="col-md-9 col-12 text-center">
            <p>No products are available</p>
          </div>
        )}
      </div>
    );
  }
}
export default DetailProductList;