import React from 'react';

function IntroductionCountry({information,order}) {
    const content={
        countryIntroductionImage:information.imageUrl,
        product:{
            headerImage:information.exportProducts.headerImage.fields.file.url,
            imageProducts:information.exportProducts.imageProducts.fields.file.url,
            description:information.exportProducts.description.content[0].content[0].value
        }
    }
    return ( 
        <div className={`service-area creative-service-wrapper ${order===1?'pt--30':'pt--100 pt-md-0 pb--120'} bg_color--1`}>
          <div className="container-sm">
            <div className="row">
              <div className="col-lg-12">
                <div className={`section-title text-center service-style--3 ${order===1?'mt_sm--0':'mt--0'}`}>
                  <img className="img__homepage h-100 w-100" src={content.countryIntroductionImage} alt="Blog Images" />
                </div>
              </div>
            </div>
          </div>
          <div className="container-sm">
            <div className={`row ml-0 p-0 creative-service col-lg-12 ${order===1?'':'flex-row-reverse'}`}>
              <div className="col-12 col-md-6 p-md-0 mt-4 mt-md-0">
                <img
                  className="w-100 h-100"
                  src={content.product.headerImage}
                  alt="About Images"
                />
              </div>
              <div className={`col-md-6 d-flex flex-column p-md-0 ${order===1?' pt-4':'mt-4 mt-md-0'}`}>
                <div className="p-0" style={{ height: '310px', flex: '0' }}>
                  <img
                    className="w-100 h-100"
                    src={content.product.imageProducts}
                    alt="About Images"
                  />
                </div>
                <div
                  className="exportToVietNam__description mt-sm-0 pt-4 pl--10 pr--10 pr_sm--0"
                  style={{ backgroundColor: '#02284d', flex: '1' }}
                >
                  <p className="text-light">
                    {content.product.description}
                  </p>
                  <div className="about-button pb-4 m-auto m-md-0" style={{ width: 'fit-content' }}>
                    <a
                      className="rn-button-style--2 btn-solid"
                      href={`${process.env.REACT_APP_MAIN_DOMAIN}/export-to-${order===1?'vn':'au'}`}
                    >
                      {localStorage.getItem('language') === 'vi-VN' ? 'Xem sản phẩm' : 'View products'}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     );
}

export default IntroductionCountry;