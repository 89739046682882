import React, { Component } from "react";
import { Link } from "react-router-dom";
import PageHelmet from "../component/common/Helmet";
import Product from "./product/Product";
import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Header from "../component/header/CustomHeader";
import { client } from "../../client";
import CallAction from "./callaction/CallAction";
import FooterTwo from "../component/footer/FooterTwo";
class BusinessRepresentativeDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      businessPage: {
        loading: true,
        error: null,
        name: "",
        logo: "",
        detail: "",
      },
      productData: {
        products: [],
        total: 0,
        category: [],
      },
      size: 4,
    };
  }

  loadMore() {
    this.setState({
      size: this.state.size + 4,
    });
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    Promise.all([
      client.getEntry(id),
      client.getEntries({
        content_type: "product",
        links_to_entry: id,
      }),
      client.getEntries({
        content_type: "category",
      }),
    ])
      .then(([rawBusiness, rawProducts, categories]) => {
        const { sys, fields } = rawBusiness;
        const { name, logo, detail } = fields;
        const products = rawProducts.items.map((product) => {
          const { sys, fields } = product;
          const categories = fields.categories.map(
            (category) => category.fields.name
          );
          return {
            id: sys.id,
            imageUrl: fields.image.fields.file.url,
            name: fields.name,
            categories: categories,
          };
        });
        const category = categories.items.flatMap((element) => {
          return { name: element.fields.name, id: element.sys.id };
        });
        this.setState({
          businessPage: {
            loading: false,
            id: sys.id,
            name: name,
            logo: logo.fields.file.url,
            detail: detail,
          },
          productData: {
            products: products,
            total: rawProducts.total,
            category,
          },
      
        });
      })
      .catch((error) =>
        this.setState({
          error,
        })
      );
  }

  render() {
    const businessPage = this.state.businessPage;
    const products = [...this.state.productData.products];
    products.length=this.state.size;
    const richTextOptions = {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
          <div className="image mb-2">
            <img
              src={node.data.target.fields.file.url}
              alt={node.data.target.fields.title}
            />
            <figcaption>{node.data.target.fields.description}</figcaption>
          </div>
        ),
        [BLOCKS.QUOTE]: (node, children) => <blockquote>{children}</blockquote>,
        [BLOCKS.TABLE]: (node, children) => (
          <table border={2} cellPadding={1} cellSpacing={1}>
            {children}
          </table>
        ),
        [BLOCKS.TABLE_ROW]: (node, children) => {
          if (
            children.every((node) => node.nodeType === BLOCKS.TABLE_HEADER_CELL)
          ) {
            // all children are header cells, so we should wrap the row
            // with a <thead /> tag
            return (
              <thead>
                <tr>{children}</tr>
              </thead>
            );
          } else {
            // not a header row, so we can render an ordinary <tr />
            return <tr>{children}</tr>;
          }
        },
        [BLOCKS.TABLE_CELL]: (node, children) => (
          <td style={{ padding: "5px", border: "1px solid black" }}>
            {children}
          </td>
        ),
      },
    };
    return !businessPage.error ? (
      !businessPage.loading ? (
        <React.Fragment>
          <PageHelmet pageTitle="About" />
          <Header logo="/assets/images/logo/image.png" />
          {/* Start About Area  */}
          <div className="rn-about-area pt--80 pb--30 bg_color--5">
            <div className="rn-about-wrapper">
              <div className="container">
                <div className="row row--35 align-items-center">
                  <div className="col-lg-12">
                    <div className="about-inner inner">
                      <div className="section-title">
                        <h2 className="title justify-content-center d-flex">
                          {businessPage.name}
                        </h2>
                      </div>
                      <div className="row mt--5">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="about-us-list">
                            {documentToReactComponents(
                              businessPage.detail,
                              richTextOptions
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End About Area  */}

          {/* Start Related Products */}
          {products?.length > 0 ? (
            <div className="rn-about-area pt--20 pb--50 bg_color--5">
              <div className="rn-about-wrapper pb-3">
                <div className="container">
                  <div className="col-12">
                    <h2>Our Products</h2>
                  </div>
                  <div className="portfolio-area bg_color--5">
                    <div className="portfolio-sacousel-inner">
                      <div className="container">
                        <div className="row d-flex justify-content-center">
                          {products.map((value, index) => {
                            return (
                              <div
                                className="col-lg-3 col-md-6 col-sm-6 col-12"
                                key={index}
                              >
                                <Link to={`/product/${value.id}`}>
                                  <Product
                                    product={value}
                                    cate={[...this.state.productData.category]}
                                  />
                                </Link>
                              </div>
                            );
                          })}
                        </div>
                        {this.state.productData.total > this.state.size ? (
                          <div className="row mt--20">
                            <div className="w-100 d-flex justify-content-center mb-3">
                              <button
                                className="rn-button-style--2 btn-solid"
                                onClick={() => this.loadMore()}
                              >
                                Load More
                              </button>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {/* End Related Products */}
          {/* <CallAction /> */}
          <FooterTwo />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="rn-brand-area pt--80 pb--80 bg_color--1">
            <div className="container">
              <div className="d-flex justify-content-center pt--300 flex-row loading-container">
                <div class="spinner-grow mr-1 ml-1" role="status">
                  <span class="sr-only">Loading...</span>
                </div>{" "}
                <div class="spinner-grow mr-1 ml-1" role="status">
                  <span class="sr-only">Loading...</span>
                </div>{" "}
                <div class="spinner-grow mr-1 ml-1" role="status">
                  <span class="sr-only">Loading...</span>
                </div>{" "}
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    ) : null;
  }
}

export default BusinessRepresentativeDetail;
