import React, { Component } from 'react';
import Slider from 'react-slick';
import { serviceSlick, serviceSlick1 } from '../../page-demo/script';

class ServiceThree extends Component {
  render() {
    const { column, serviceList } = this.props;
    const ServiceContent = serviceList.slice(0, this.props.item);
    const isMobile = window.matchMedia('(max-width: 574px)');
    const serviceStyle = isMobile.matches ? serviceSlick1 : serviceSlick;
    let color = '';
    console.log(ServiceContent);
    return (
      <React.Fragment>
        <div className="row">
          <Slider className="rn-slick-dot dot-light" {...serviceStyle}>
            {ServiceContent.map((val, i) => {
              if (this.props.setColor) {
                if (i === 0 || i === 3) color = '#00A4E0';
                else if (i === 1 || i === 4) color = '#006BBD';
                else color = '#095692';
              }
              return (
                <a href={val.link} key={i}>
                  <div className={`${column}`}>
                    <div className="service service__style--2" style={{ backgroundColor: `${color}` }}>
                      <div className="content">
                        <h3
                          className="title service__title"
                          style={{
                            color: `${this.props.setColor ? 'white' : ''}`,
                            height: '60px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            whiteSpace: 'normal',
                            lineClamp: '2',
                            WebkitLineClamp: '2',
                            
                          }}
                        >
                          {val.title}
                        </h3>
                        <p
                          style={{
                            color: this.props.setColor ? 'white' : '',
                            height: '150px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            whiteSpace: 'normal',
                            lineClamp: '5',
                            WebkitLineClamp: '5',
                            fontSize: '17px',
                          }}
                        >
                          {val.subtitle}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              );
            })}
          </Slider>
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceThree;
