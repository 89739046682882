import React, { Component } from "react";
import { Link } from "react-router-dom";

class BrandOne extends Component {
  render() {
    const { branstyle, brands, listyle } = this.props;  
    console.log(this.props)  
    return (
      <React.Fragment>
        <ul className={`row ${branstyle}  `}>
          {brands.map((item,key) => (
            <Link to={`/business-representative/${item.id}`} style={{zIndex:"2"}} key={key} className="col-lg-3 col-12" >
              <li className={`bg-light ${listyle}`}>
                <img src={item.url} alt={item.name} />
                <p className="description" style={{lineHeight:"18px"}}>{item.summary}</p>
              </li>
            </Link>
          ))}
        </ul>
      </React.Fragment>
    );
  }
}
export default BrandOne;
