import Slide from './Slide';
import React from 'react';
import ServiceList from "../elements/service/ServiceList";

function Heading(props) {
  const { title, subtitle } = props;
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="section-title service-style--3 text-center mb--30 mt-28 mb_sm--0">
          <h2 className="title text-uppercase" style={{ fontSize: '30px' }}>
            {title}
          </h2>
          <h3>{subtitle}</h3>
        </div>
      </div>
    </div>
  );
}

function PageIntroduction(props) {
  const {
    content: { title, subtitle, slides },
  } = props;
  return (
    <div className="service-area creative-service-wrapper pt--30 pb--120 bg_color--1">
      <div className="container">
        <Heading title={title} subtitle={subtitle} />
        <Slide>
          <ServiceList item="6" column="col-12 text-left" setColor={true} serviceList={slides} />
        </Slide>
      </div>
    </div>
  );
}

export default PageIntroduction;
