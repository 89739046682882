import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

class Accordion01 extends Component {
  render() {
    const { advantageArray } = this.props;
    return (
      <Accordion className="accodion-style--1" allowZeroExpanded="true">
        {advantageArray &&
          advantageArray.map((item) => {
            return (
              <AccordionItem key={item.id}>
                <AccordionItemHeading>
                  <AccordionItemButton>{item.title}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>{item.description}</p>
                </AccordionItemPanel>
              </AccordionItem>
            );
          })}
      </Accordion>
    );
  }
}

export default Accordion01;
