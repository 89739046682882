import React, { Component } from "react";
import Slider from "react-slick";
import { slideSlick } from "../../page-demo/script";
class SliderTwo extends Component {
  render() {
    const slider = this.props.dataSlider;
    const custom= this.props.custom||false;
    return (
      <div className="slider-activation">
        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
          {slider.map((value, index) => (
            <div key={index}>
              <div
                className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image ${custom?"slider__homepage":""}`}
                style={{ backgroundImage: `url(${value.slideImageURL})`,width:"100vw",position:"relative"}}
              >
                <div style={{position:"absolute",top:"80%",left:"50%",transform:"translate(-50%,-50%)"}}>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition}`}>
                          {value.category ? <span>{value.category}</span> : ""}
                          {value.title ? (
                              <a
                                className="rn-button-style--2 btn-solid"
                                href={`${process.env.REACT_APP_SUB_DOMAIN}/users/login/${encodeURIComponent(window.location.href)}`}
                              >
                                {value.title}
                              </a>
                          ) : (
                            ""
                          )}
                          {value.subtitle ? (
                            <p className="description">{value.subtitle}</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
export default SliderTwo;
