import React, { Fragment } from "react";
import Helmet from "../component/common/Helmet";
import Header from "../component/header/CustomHeader";
import SliderTwo from "../component/slider/SliderTwo";
import FooterTwo from "../component/footer/FooterTwo";
import { GetHomePageContent } from "../utils/GetHomePageContent";
import BusinessRepresentative from "./BusinessRepresentative";
import ForumSlide from "./ForumSlide";
import PageIntroduction from "./PageIntroduction";
import IntroductionSection from "./SectionIntroduction";
import IntroductionCountry from "./ExportSection";
import { useEffect, useState } from "react";
function Home() 
{
  const [data, setData] = useState({
    isOpen: false,
    content: {
      loading: true,
      error: null,
      pageIntroduction: {}, // Page Introduction title & sub title
      exportToAustralia: {},
      exportToVietNam: {},
      businessRepresentative: {}, // Business Representative title & sub title
      businessConsulting: {}, // Business Consulting
      aboutUs: {}, // About Us
      businessForum: {}, // Business Forum Introduction
    },
    carouselSlides: [], // Carousel Slide
  }); 
  useEffect(() => {
    const fetchData = async () => {
      setData(await GetHomePageContent());
    };
    fetchData();
  }, []);
  const content = data.content;
  return !content.loading && !content.error ? (
    <Fragment>
      <Helmet pageTitle="Business" />
      <Header logo="/assets/images/logo/image.png" />
      <SliderTwo dataSlider={data?.carouselSlides} custom={true} />
      <PageIntroduction content={content.pageIntroduction} />
      <IntroductionCountry information={content.exportToVietNam} order={1} />
      <IntroductionCountry information={content.exportToAustralia} order={2} />
      <BusinessRepresentative content={content.businessRepresentative} />
      <IntroductionSection content={content.aboutUs} />
      <IntroductionSection content={content.businessConsulting} />
      <ForumSlide content={content.businessForum} />
      <FooterTwo />
    </Fragment>
  ) : null;
}
export default Home;
