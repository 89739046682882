import React from 'react';
import Accordion01 from '../elements/Accordion';
function Heading(props) {
  const { title, slogan, description, features } = props;
  return (
    <div className="about-inner inner">
      <div className="section-title">
        <h2 className="title newTitle text__big">{title}</h2>
        <h4 className="newSubtitle">{slogan}</h4>
        <p className="description">{description}</p>
      </div>
      <div className="accordion-wrapper mt--30">
        <Accordion01 advantageArray={features} />
      </div>
      <div className="about-button mt--50">
        <a className="rn-button-style--2 btn-solid" href={`${features?`${process.env.REACT_APP_SUB_DOMAIN}/consulting`:`${process.env.REACT_APP_MAIN_DOMAIN}/about`}`}>
          {localStorage.getItem('language') === 'vi-VN' ? 'Xem thêm' : 'View more'}
        </a>
      </div>
    </div>
  );
}
function Image({ imageUrl }) {
  return (
    <div className="thumbnail position-relative">
      <img className="w-100" src={imageUrl} alt="About Images" />
    </div>
  );
}

function IntroductionSection(props) {
  const {
    content: { title, slogan, description, imageUrl, features},
  } = props;

  return (
    <div className={`rn-about-area ${features?'':'pt--80'} pb--80 bg_color--5`}>
      <div className="container">
        <div className="row row--35 align-items-center">
          <div className={`col-lg-6 order-1 ${features?'order-lg-1':'order-lg-2'}`}>
            <Image imageUrl={imageUrl} />
          </div>
          <div className={`col-lg-6 order-2 ${features?'order-lg-2':'order-lg-1'}`}>
            <Heading title={title} slogan={slogan} description={description} features={features} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default IntroductionSection;
