import React, { Component } from 'react';
import axios from 'axios';
import { client } from '../../client';
import { calculateTimesSinceDate } from '../utils/calculateTime';
import Header from '../component/header/CustomHeader';
import ProductList from './ProductList';
import BrandOne from './Brand';
import PageHelmet from '../component/common/Helmet';
import FooterTwo from '../component/footer/FooterTwo';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: {},
      search: '',
      business: [],
      product: [],
      consulting: [],
      posts: [],
      loading: true,
    };
  }
  componentDidMount() {
    try {
      const query = new URLSearchParams(this.props.location.search);
      const searchString = query.get('search');
      const searchQuery = `*${searchString.toLowerCase()}*`;
      Promise.all([
        client.getEntries({
          content_type: 'searchPage',
        }),
        client.getEntries({
          content_type: 'business',
          'fields.name[match]': searchQuery,
        }),
        client.getEntries({
          content_type: 'product',
          'fields.name[match]': searchQuery,
        }),
        client.getEntries({
          content_type: 'businessConsultingPost',
          'fields.title[match]': searchQuery,
        }),
        client.getEntries({
          content_type: 'businessConsultingPost',
          'fields.postSummary[match]': searchQuery,
        }),
        client.getEntries({
          content_type: 'businessConsultingPost',
          'fields.contents[match]': searchQuery,
        }),
        axios.post(`${process.env.REACT_APP_API}/posts/search`, {
          search: searchString,
        }),
      ]).then(
        ([
          rawSearchPage,
          rawBusiness,
          rawProduct,
          rawTitleConsulting,
          rawSummrayConsulting,
          rawContentConsulting,
          posts
        ]) => {
          const searchPage = rawSearchPage.items[0];
          const content = {
            id: searchPage.sys.id,
            title: searchPage.fields.title,
            subTitle: searchPage.fields.subTitle,
            imageUrl: searchPage.fields.headerImage.fields.file.url,
          };
          const business = rawBusiness.items.map((item) => {
            const { sys, fields } = item;
            const { name, logo, detail } = fields;
            return {
              id: sys.id,
              name: name,
              url: logo.fields.file.url,
            };
          });
          const product = rawProduct.items.map((item) => {
            const { sys, fields } = item;
            const { name, categories, image } = fields;
            return {
              id: sys.id,
              name: name,
              categories: categories ? categories.map((category) => category.fields.name) : [],
              imageUrl: image.fields.file.url,
            };
          });
          rawTitleConsulting.items.map((item) => {
            const { sys, fields } = item;
            const { id, createdAt } = sys;
            const { title, postSummary, tags, author, postImage } = fields;
            const timeDiff = calculateTimesSinceDate(new Date(createdAt), new Date());
            const titleConsulting = {
              id: id,
              title: title,
              postImage: {
                title: postImage.fields.title,
                url: postImage.fields.file.url,
              },
              postSummary: postSummary,
              timeDiff: timeDiff,
              tags: tags ? tags : [],
              author: author,
            };
            this.state.consulting.push(titleConsulting);
          });
          rawSummrayConsulting.items.map((item) => {
            const { sys, fields } = item;
            const { id, createdAt } = sys;
            const { title, postSummary, tags, author, postImage } = fields;
            const timeDiff = calculateTimesSinceDate(new Date(createdAt), new Date());
            const summaryConsulting = {
              id: id,
              title: title,
              postImage: {
                title: postImage.fields.title,
                url: postImage.fields.file.url,
              },
              postSummary: postSummary,
              timeDiff: timeDiff,
              tags: tags ? tags : [],
              author: author,
            };
            this.state.consulting.push(summaryConsulting);
          });
          rawContentConsulting.items.map((item) => {
            const { sys, fields } = item;
            const { id, createdAt } = sys;
            const { title, postSummary, tags, author, postImage } = fields;
            const timeDiff = calculateTimesSinceDate(new Date(createdAt), new Date());
            const contentConsulting = {
              id: id,
              title: title,
              postImage: {
                title: postImage.fields.title,
                url: postImage.fields.file.url,
              },
              postSummary: postSummary,
              timeDiff: timeDiff,
              tags: tags ? tags : [],
              author: author,
            };
            this.state.consulting.push(contentConsulting);
          });
          this.setState({
            content,
            business,
            product,
            posts: posts.data,
            search: searchString,
            loading: false,
          });
        },
      );
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { business, product, consulting, posts, loading, content } = this.state;
    const filteredConsulting = consulting.reduce((accumulator, current) => {
      const duplicate = accumulator.find((obj) => obj.id === current.id);
      if (!duplicate) {
        return accumulator.concat([current]);
      } else {
        return accumulator;
      }
    }, []);

    return (
      <React.Fragment>
        <Header logo="/assets/images/logo/image.png" />

        {loading ? (
          <div className="rn-brand-area pt--80 pb--80 bg_color--1">
            <div className="container">
              <div className="d-flex justify-content-center pt--300 flex-row loading-container">
                <div class="spinner-grow mr-1 ml-1" role="status">
                  <span class="sr-only">Loading...</span>
                </div>{' '}
                <div class="spinner-grow mr-1 ml-1" role="status">
                  <span class="sr-only">Loading...</span>
                </div>{' '}
                <div class="spinner-grow mr-1 ml-1" role="status">
                  <span class="sr-only">Loading...</span>
                </div>{' '}
              </div>
            </div>
          </div>
        ) : (
          <>
            <PageHelmet pageTitle="Search" />

            {/* Start Breadcrump Area */}
            <div
              className="rn-page-title-area mt--20 pt--80 pb--150 bg_image"
              style={{
                backgroundImage: `url("${content.imageUrl}")`,
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="rn-page-title pt--100">
                      <h2 className="theme-gradient mb-0">{content.title}</h2>
                      <h1 className="title theme-gradient">
                        <b>{`${content.subTitle} ${this.state.search}`}</b>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Breadcrump Area */}

            {business.length == 0 && product.length == 0 && filteredConsulting.length == 0 && posts.length == 0 ? (
              <div className="rn-brand-area pt--80 pb--80 bg_color--1">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="section-title mb--30 mb_sm--0 mt-5 fontWeight-500">
                        <h3>NOTHING FOUND </h3>
                        <h5 className="text-muted">
                          Sorry, but nothing matched your search terms. Please try again with some different keywords.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {/* Start Business Representative  */}
                {business.length > 0 && (
                  <div className="rn-brand-area pt--30 pb--30 bg_color--1">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="section-title service-style--3 mb_sm--0">
                            <h2 className="title">Business</h2>
                          </div>
                          {business ? (
                            <BrandOne
                              branstyle="brand-list branstyle--2 justify-content-start"
                              listyle="brand-list-li-au mt-3"
                              brands={business}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* End Business Representative  */}
                {/* Start Product List*/}
                {product.length > 0 && (
                  <div className="portfolio-area pt--10 pb--50 bg_color--1">
                    <div className="portfolio-sacousel-inner">
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="section-title service-style--3 mb--30 mb_sm--0">
                              <h2 className="title">Product </h2>
                            </div>
                          </div>
                        </div>
                        <div className="row d-flex justify-content-start">
                          <ProductList
                            stylevariation="text-left mt--40"
                            column="col-lg-3 col-md-4 col-sm-6 col-12 h-100"
                            item={product.length}
                            productList={product}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* End Product List */}
                {/* Start Business Forum */}
                {posts.length > 0 && (
                  <div className="service-area creative-service-wrapper pt--10 pb--50 bg_color--1   ">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="section-title service-style--3 mb_sm--0">
                            <h2 className="title">Forum</h2>
                          </div>
                        </div>
                      </div>
                      <div className="row creative-service">
                        <div className="col-12">
                          <div className="row">
                            {posts.map((post, i) => {
                              return (
                                <div className="w-100 col-md-6 col-lg-4 text-left" key={i}>
                                  <a
                                    style={{
                                      display: 'block',
                                    }}
                                    href={`${process.env.REACT_APP_SUB_DOMAIN}/post/${post._id}`}
                                  >
                                    <div className="service service__style--2">
                                      <div className="content">
                                        <h3 className="title">{post.title}</h3>
                                        {post.description.length > 100 ? (
                                          <p>{post.description.substring(0, 100) + '...'}</p>
                                        ) : (
                                          <p>{post.description}</p>
                                        )}
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* End Business Forum  */}
                {/* Start Business Consulting */}
                {filteredConsulting.length > 0 && (
                  <div className="portfolio-area pb--80 bg_color--1">
                    <div className="container">
                      <div className="row d-flex justify-content-center">
                        <div className="col-lg-12">
                          <div className="section-title service-style--3 mb_sm--0">
                            <h2 className="title">Consulting Post</h2>
                          </div>
                        </div>
                        <div className="col-12 ml-0 d-flex flex-wrap">
                          {filteredConsulting.map(({ id, postImage, title, postSummary, timeDiff, tags, author }) => (
                            <div className="row w-100 col-md-6 col-lg-4">
                              <a
                                className="post-card list-group-item-action
                                        flex-column align-items-center m-3 p-4
                                        shadow-lg"
                                href={`${process.env.REACT_APP_SUB_DOMAIN}/consulting-post/${id}`}
                              >
                                {/* <div className="post-image col-12 mb-3 p-0">
                                  <img src={postImage.url} alt={postImage.title} width="100%" />
                                </div> */}
                                <div className="col-12 d-flex justify-content-between p-0">
                                  <div className="col-12 p-0">
                                    <h3 className="mb-2">{title}</h3>
                                    {tags.length ? (
                                      <div className="tag">
                                        {tags.map((el) => (
                                          <span className="badge badge-success mr-3 mt-2 mb-2 pt-2 pb-2 pr-3 pl-3">
                                            {el}{' '}
                                          </span>
                                        ))}
                                      </div>
                                    ) : null}
                                    <p>{postSummary}</p>
                                    {/* <div className="d-flex justify-content-between">
                                      <p>Posted by: {author}</p>

                                      <p className="time-diff mr-1">{timeDiff}</p>
                                    </div> */}
                                  </div>
                                </div>
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* End Business Consulting */}
              </>
            )}
          </>
        )}
        {/* <CallAction /> */}
        <FooterTwo />
      </React.Fragment>
    );
  }
}
export default Search;
