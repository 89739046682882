import React, { Component } from 'react';
import PageHelmet from '../component/common/Helmet';
import { BLOCKS } from '@contentful/rich-text-types';
import { MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { client } from '../../client';
import Header from '../component/header/CustomHeader';
import FooterTwo from '../component/footer/FooterTwo';
import Slider from 'react-slick';
import { serviceSlick3, serviceSlick2, serviceSlick4 } from '../page-demo/script';
import MyVerticallyCenteredModal from './Modal.jsx';

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutUsPage: {
        loading: true,
        error: null,
        title: '',
        introduction: [],
        service: [],
        feedback: [],
        team: {},
        headerImageUrl: {},
      },
      brandImages: [],
      companyIntroduction: [],
      modalShow: false,
      contentForModal: {},
    };
  }
  setModalShow = (content) => {
    this.setState((prevState) => {
      return { ...prevState, modalShow: !prevState.modalShow, contentForModal: content };
    });
  };
  componentDidMount() {
    client
      .getEntries({ content_type: 'aboutUsPage', include: 10, locale: localStorage.getItem('language') })
      .then((rawAbouUstPage) => {
        const aboutUsPage = rawAbouUstPage.items[0].fields;
        const { title } = aboutUsPage;
        const headerImageUrl = {
          id: aboutUsPage.headerImage.sys.id,
          url: aboutUsPage.headerImage.fields.file.url,
        };
        this.setState({
          aboutUsPage: {
            loading: false,
            title: title,
            introduction: aboutUsPage.introduction,
            service: aboutUsPage.service,
            feedback: aboutUsPage.feedback.fields,
            headerImageUrl: headerImageUrl,
          },
        });
      })
      .catch((error) =>
        this.setState({
          aboutUsPage: {
            error,
          },
        }),
      );
  }

  render() {
    let serviceStyle;
    const aboutUsPage = this.state.aboutUsPage;
    console.log(aboutUsPage);
    const isMobile = window.matchMedia('(max-width: 668px)');
    const isTablet = window.matchMedia('(max-width: 1207px)');
    const isDesktop = window.matchMedia('(min-width: 1208px)');
    if (isMobile.matches) {
      serviceStyle = serviceSlick2;
    } else if (isTablet.matches) {
      serviceStyle = serviceSlick4;
    } else if (isDesktop.matches) {
      serviceStyle = serviceSlick3;
    }
    const renderIntroduction = {
      renderMark: {
        [MARKS.BOLD]: (text) => <b>{text}</b>,
      },
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
          <div className="image mb-2">
            <img src={node.data.target.fields.file.url} alt={node.data.target.fields.title} />
            <figcaption className="">{node.data.target.fields.description}</figcaption>
          </div>
        ),
        [BLOCKS.PARAGRAPH]: (node, children) => <p className="col-12 text-left text-xl-justify pl-xl-5">{children}</p>,
        [BLOCKS.TEXT]: (node, children) => <>{children}</>,
        [BLOCKS.UL_LIST]: (node, children) => (
          <p className="col-12 text-left text-xl-justify pl-xl-5">
            <ul className="">{children}</ul>
          </p>
        ),
        [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
        [BLOCKS.LIST_ITEM]: (node, children) => <li>{node.content[0].content[0].value}</li>,
      },
    };
    return !aboutUsPage.loading && !aboutUsPage.error ? (
      <React.Fragment>
        <MyVerticallyCenteredModal
          show={this.state.modalShow}
          content={this.state.contentForModal}
          onHide={() => this.setModalShow('')}
        />
        <Header logo="/assets/images/logo/image.png" />
        <PageHelmet pageTitle="About" />
        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area mt--120 img__introduction bg_image"
          style={{
            backgroundImage: `url("${aboutUsPage?.headerImageUrl?.url}")`,
          }}
        ></div>
        {/* End Breadcrump Area */}

        {/* Start About Area  */}
        <div className="rn-about-area pb--30 bg_color--5">
          <div className="rn-about-wrapper">
            <div className="container business__representative px-4 px-sm-0 pt-4">
              {aboutUsPage.introduction.map((item, index) => (
                <div className={`row mb-4 my-xl-0 ${index % 2 === 0 ? 'flex-xl-row-reverse' : ''} align-items-center`}>
                  <div className="col pt-4 m-auto">
                    <h2 className="heading text-uppercase text-sm-center text-xl-left pl-xl-5">{item.fields.title}</h2>
                    {documentToReactComponents(item.fields.description, renderIntroduction)}
                  </div>
                  <div className="col m-auto m-xl-0">
                    <div className="row align-items-center">
                      <div className="col text-center text-xl-left" style={{ padding: 0 }}>
                        <img src={item.fields.image.fields.file.url} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start Why Should Choose Us Area  */}
        <div className="why__choose__us">
          <div className="background-img row">
            {/* <div
              className="col-12 img__page_AboutUsPage"
              style={{
                backgroundImage: `url("${aboutUsPage.service.fields.image.fields.file.url}")`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            ></div> */}
            <div
              className="rn-page-title-area img__page_BRPage bg_image"
              style={{
                backgroundImage: `url("${aboutUsPage.service.fields.image.fields.file.url}")`,
                paddingTop:"20px",
                paddingBottom:"20px"
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-xl-12 p-0">
                    <div className="rn-page-title text-center ">
                      <h2 className="title theme-gradient" style={{fontSize:"52px"}}>
                       {aboutUsPage.service.fields.title}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container about__us m-auto pb-5">
            <div className="row pt-5">
              {aboutUsPage.service.fields.service.map((item, index) => (
                <div className="col-12 col-xl-4 mb-4 mb-xl-0" key={index}>
                  <div className="col-12 col-md-10 m-auto py-4">
                    <div className="heading text-uppercase text-center">
                      <img src={item.fields.image.fields.file.url} alt="" style={{ width: '25%' }} />
                    </div>
                    <h2 className="pt-4">{item.fields.title}</h2>
                    <p className="text-center m-auto">{item.fields.description.content[0].content[0].value}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Why Should Choose Us Area */}

        {/* Start Feedback Area  */}
        <div
          className="rn-page-title-area bg_image feedback-area"
          style={{
            backgroundImage: `url("${aboutUsPage.feedback.background.fields.file.url}")`,
          }}
        >
          <h2 className="heading text-uppercase">{aboutUsPage.feedback.title}</h2>
          <div className="container-lg">
            <div className="row">
              <Slider {...serviceStyle}>
                {aboutUsPage.feedback.description.map((item, index) => {
                  let color = '#fff';
                  return (
                    <div
                      className="col-12 mt--20"
                      key={index}
                      onClick={() =>
                        this.setModalShow({
                          content: item.fields.description.content[0].content[0].value,
                          author: item.fields.name,
                        })
                      }
                    >
                      <div
                        className="card__special feedback-detail d-flex flex-row m-auto"
                        style={{ backgroundColor: color, cursor: 'pointer' }}
                      >
                        <span className="feedback__detail-client-content">
                          <p className="col-12">{item.fields.description.content[0].content[0].value}</p>
                        </span>
                        <h5 className="title__special">
                          {item.fields.name.split('').map((name, index) => {
                            if (name == '-') return <br />;
                            else return name;
                          })}
                        </h5>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
        {/* End Feedback Area */}
        {/* <CallAction /> */}
        <FooterTwo />
      </React.Fragment>
    ) : null;
  }
}
export default About;
