import React, { Component } from 'react';
import PageHelmet from '../component/common/Helmet';
import DetailProductList from './product/DetailProductList';
import { client } from '../../client';
import Header from '../component/header/CustomHeader';
import FooterTwo from '../component/footer/FooterTwo';

class ExportToVietNam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: {
        loading: true,
        error: null,
        title: '',
        categories: [],
        products: [],
        headerImageUrl: {},
      },
    };
  }

  componentDidMount() {
    client
      .getEntries({
        include: '10',
        content_type: 'exportToVietNamPage',
        limit: 1,
        locale: localStorage.getItem('language'),
      })
      .then((rawExportToVietNamPage) => {
        const exportToVietNamPage = rawExportToVietNamPage.items[0].fields;
        const title = exportToVietNamPage.title;
        const categories = exportToVietNamPage.categories.map((category) => ({
          id: category.sys.id,
          name: category.fields.name,
        }));
        const headerImageUrl = {
          id: exportToVietNamPage.headerImage.sys.id,
          url: exportToVietNamPage.headerImage.fields.file.url,
        };
        this.setState({
          content: {
            loading: false,
            title: title,
            categories: categories,
            headerImageUrl: headerImageUrl,
          },
        });
      })
      .catch((error) =>
        this.setState({
          content: {
            error,
          },
        }),
      );
  }

  render() {
    const content = this.state.content;
    return !content.loading && !content.error ? (
      <React.Fragment>
        <Header logo="/assets/images/logo/image.png" />
        <PageHelmet pageTitle="Export to Viet Nam" /> {/* Start Breadcrump Area */}
        <div className="rn-page-title-area mt--100 introduction_page_img" style={{ width: '100vw',height:'auto' }}>
          {/* {window.innerWidth>1280?<img src={content?.headerImageUrl?.url} className='w-100 h-100' alt=""/>} */}
          {window.innerWidth > 1280 ? (
            <div
              className="Background Image"
              style={{
                backgroundImage: `url(${content?.headerImageUrl?.url})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                paddingTop: '310px',
                paddingBottom: '220px',
              }}
            ></div>
          ) : (
            <div className="Background Image">
              <img src={content?.headerImageUrl?.url} alt="HeaderImage" style={{ width: '100%' }} />
            </div>
          )}
        </div>
        {/* End Breadcrump Area */}
        <div className="portfolio-area pb--50 bg_color--5">
          <div className="portfolio-sacousel-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                    <h2 className="title" style={{ color: '#f1c100' }}>
                      {content?.title}
                    </h2>
                  </div>
                </div>
              </div>
              <DetailProductList
                categoryStyle="category-vn"
                paginationStyle="pagination-vn"
                column="col-lg-4 col-md-6 col-12"
                item="9"
                categories={content.categories}
                productsType="Export to Viet Nam"
              />
            </div>
          </div>
        </div>
        {/*End Export to Viet Nam */}
        {/* <CallAction /> */}
        <FooterTwo />
      </React.Fragment>
    ) : null;
  }
}

export default ExportToVietNam;
