import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import Header from "../component/header/CustomHeader";
import { client } from "../../client";
import FooterTwo from "../component/footer/FooterTwo";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactPage: {}, // Contact Us Page
    };
  }

  componentDidMount() {
    client
      .getEntries({ content_type: "contactUsPage" })
      .then((rawContactPage) => {
        const contactPage = rawContactPage.items[0].fields;
        this.setState({
          contactPage,
        });
      })
      .catch((error) =>
        this.setState({
          contactPage: {
            error,
          },
        })
      );
  }

  render() {
    return (
      <React.Fragment>
        <Header logo="/assets/images/logo/image.png" />

        <PageHelmet pageTitle="Contact" />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image"
          style={{backgroundImage: `url("${this.state.contactPage.headerImage?.fields.file.url}")`}}
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">
                    {this.state.contactPage.title || "Contact with Us"}
                  </h2>
                  <p>
                    {this.state.contactPage.subtitle ||
                      "Contrary to popular belief, Lorem Ipsum is not simply random text."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Contact Top Area  */}
        <div className="rn-contact-top-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row d-flex justify-content-center">
              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiHeadphones />
                  </div>
                  <div className="inner">
                    <h4 className="title">Contact With Phone Number</h4>
                    {this.state.contactPage.phoneNumber &&
                      this.state.contactPage.phoneNumber.map((number) => {
                        return (
                          <p>
                            <a href={`tel:${number}`}>{number}</a>
                          </p>
                        );
                      })}
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title">Email Address</h4>
                    {this.state.contactPage.emailAddress &&
                      this.state.contactPage.emailAddress.map((email) => {
                        return (
                          <p>
                            <a href={`mailto:${email}`}>{email}</a>
                          </p>
                        );
                      })}
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title">Location</h4>
                    {this.state.contactPage.location &&
                      this.state.contactPage.location.map((location) => {
                        return <p>{location}</p>;
                      })}
                  </div>
                </div>
              </div>
              {/* End Single Address  */}
            </div>
          </div>
        </div>
        {/* End Contact Top Area  */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}
      </React.Fragment>
    );
  }
}
export default Contact;
