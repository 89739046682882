import React, { Component, Fragment } from "react";
import { client } from "../../client";
import DetailProductList from "./product/DetailProductList";
import Header from "../component/header/CustomHeader";
import PageHelmet from "../component/common/Helmet";
import FooterTwo from "../component/footer/FooterTwo";

class ExportToAU extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: {
        loading: true,
        error: null,
        title: "",
        subtitle: "",
        categories: [],
        products: [],
        headerImageUrl: {},
      },
    };
  }

  componentDidMount() {
    client
      .getEntries({
        include: "10",
        content_type: "exportToAustraliaPage",
        limit: 1,
        locale: localStorage.getItem('language')
      })
      .then((rawExportToAustraliaPage) => {
        const exportToAustraliaPage = rawExportToAustraliaPage.items[0].fields;
        const title = exportToAustraliaPage.title;
        const categories = exportToAustraliaPage.categories.map((category) => ({
          id: category.sys.id,
          name: category.fields.name,
        }));
        const headerImageUrl = {
          id: exportToAustraliaPage.headerImage.sys.id,
          url: exportToAustraliaPage.headerImage.fields.file.url,
        };

        this.setState({
          content: {
            loading: false,
            title: title,
            categories: categories,
            headerImageUrl: headerImageUrl,
          },
        });
      })
      .catch((error) =>
        this.setState({
          content: {
            error,
          },
        })
      );
  }

  render() {
    const content = this.state.content;
    return !content.loading && !content.error ? (
      <Fragment>
        <Header logo="/assets/images/logo/image.png" />
        <PageHelmet pageTitle="Export to Australia" />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area mt--120 introduction_page_img"
          style={{width: "100vw",height: "auto"}}
        >
          {/* <img src={content?.headerImageUrl?.url} className='w-100 h-100' alt=""/> */}
          {window.innerWidth > 1280 ? (
            <div
              className="Background Image"
              style={{
                backgroundImage: `url(${content?.headerImageUrl?.url})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                paddingTop: '280px',
                paddingBottom: '220px',
              }}
            ></div>
          ) : (
            <div className="Background Image">
              <img src={content?.headerImageUrl?.url} alt="HeaderImage" style={{ width: '100%' }} />
            </div>
          )}
        </div>
        {/* End Breadcrump Area */}

        {/* Export to Australia */}
        <div className="portfolio-area pb--50 bg_color--5">
          <div className="portfolio-sacousel-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                    <h2 className="title" style={{color:"#d50b00"}}>{content?.title}</h2>
                  </div>
                </div>
              </div>
              <DetailProductList
                categoryStyle="category-au"
                paginationStyle="pagination-au"
                column="col-lg-4 col-md-6 col-12"
                item="9"
                categories={content.categories}
                productsType="Export to Australia"
              />
            </div>
          </div>
        </div>
        {/*End Export to Australia */}
        {/* <CallAction /> */}
        <FooterTwo />
      </Fragment>
    ) : null;
  }
}
export default ExportToAU;
