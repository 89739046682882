// react
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { client } from "../../client";

// components
import Product from "./product/Product"

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: null,
      cate:[]
    };
    this.handleCategoryOnClick = this.handleCategoryOnClick.bind(this);
  }

  handleCategoryOnClick(name) {
    this.setState({ selectedCategory: name });
  }
  componentDidMount()
  {
    client.getEntries({
      content_type: "category",
    })
    .then(cate=>{
     
      const category= cate.items.flatMap(element => 
        {
          return {name:element.fields.name,id:element.sys.id};
        });
      this.setState({cate:category});
    })
  }

  render() {
    const { column, productList } = this.props;
    const list =
      productList && !this.state.selectedCategory
        ? productList.slice(0, this.props.item)
        : null;

    return (
      <React.Fragment>
        {list.map((value, index) => {
          return (
            <div className={`${column}`} key={index}>
              <Link to={`/product/${value.id}`}>
                <Product product={value} cate={this.state.cate}/>
              </Link>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}
export default ProductList;