import React from 'react';
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

class Pagination extends React.Component {

    render() {
        const { limitPage, currentPage, setCurrentPage, totalPage } = this.props;
        const pageNumbers = [];
        let startPage = 1;
        let endPage = totalPage;
        if (totalPage > limitPage) {
            if (currentPage > Math.round(limitPage / 2)) {
                startPage = currentPage - Math.round(limitPage / 2);
                endPage = currentPage + Math.round(limitPage / 2);
            }  
            if (currentPage >= (totalPage - Math.round(limitPage / 2))) {
                startPage = totalPage - limitPage;
                endPage = totalPage;
            }
            if(currentPage <= Math.round(limitPage / 2)){
                startPage = 1;
                endPage = limitPage+1;
            }
        }
        for (let i = startPage; i <= endPage; i++) {
            if (currentPage === i) {
                pageNumbers.push(
                    <li key={i} className="active" onClick={() => setCurrentPage(i)}>
                        {i}
                    </li>
                )
            } else {
                pageNumbers.push(
                    <li key={i} onClick={() => setCurrentPage(i)}>
                        {i}
                    </li>
                )
            }
        }
        
        return (
            <div className="rn-pagination text-center">
                <ul className={`page-list ${this.props.style}`}>
                    <li key="first-page" onClick={() => setCurrentPage(1)}><FaAngleLeft /></li>
                    {pageNumbers}
                    <li key="last-page" onClick={() => setCurrentPage(totalPage)}><FaAngleRight /></li>
                </ul>
            </div>
        );
    }
}

export default Pagination;