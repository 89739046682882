// react
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Pagination from "./common/Pagination";
import { client } from "../../client";
// components
import Product from "./product/Product";
import Categories from "./common/CustomCate";
import Header from "../component/header/CustomHeader";
import PageHelmet from "../component/common/Helmet";
import { withRouter } from "react-router-dom";
import FooterTwo from "../component/footer/FooterTwo";

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: {},
      categoriesName: "",
      categories: [],
      currentPage: 1,
      productData: {
        loading: true,
        error: null,
        products: [],
        total: 0,
      },
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    Promise.all([
      client.getEntries({
        content_type: "product",
        limit: this.props.item,
        skip: (this.state.currentPage - 1) * 8,
        links_to_entry: id,
      }),
      client.getEntries({
        content_type: "category",
      }),
      client.getEntries({
        content_type: "categoryPage",
      }),
      client.getEntry(id),
    ])
      .then(
        ([rawProductData, rawCategory, rawCategoryPage, rawCategoryName]) => {
          console.log("raw", rawCategoryPage);
          const categoryPage = rawCategoryPage.items[0];
          const content = {
            id: categoryPage.sys.id,
            title: categoryPage.fields.title,
            subTitle: categoryPage.fields.subTitle,
            imageUrl: categoryPage.fields.headerImage.fields.file.url,
          };
          console.log("content1", content);
          const products = rawProductData.items.map((product) => {
            const { sys, fields } = product;
            const categories = fields.categories.map(
              (category) => category.fields.name
            );
            return {
              id: sys.id,
              imageUrl: fields.image.fields.file.url,
              name: fields.name,
              categories: categories,
            };
          });
          const categories = rawCategory.items.map((category) => {
            const { sys, fields } = category;
            return {
              id: sys.id,
              name: fields.name,
            };
          });
          this.setState({
            content: content,
            categoriesName: rawCategoryName.fields.name,
            selectedCategory: id,
            categories,
            productData: {
              loading: false,
              error: null,
              products: products,
              total: rawProductData.total,
            },
          });
        }
      )
      .catch((error) =>
        this.setState({
          product: {
            error: error,
          },
        })
      );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.currentPage !== prevState.currentPage ||
      this.state.selectedCategory !== prevState.selectedCategory
    ) {
      Promise.all([
        client.getEntries({
          content_type: "product",
          limit: this.props.item,
          skip: (this.state.currentPage - 1) * 8,
          links_to_entry: this.state.selectedCategory,
        }),
        this.state.selectedCategory &&
          client.getEntry(this.state.selectedCategory),
      ])
        .then(([rawProductData, rawCategoryName]) => {
          if (rawCategoryName === "") {
            rawCategoryName = {
              ...rawCategoryName,
              fields: { name: "All Products" },
            };
          }
          const products = rawProductData.items.map((product) => {
            const { sys, fields } = product;
            const categories = fields.categories.map(
              (category) => category.fields.name
            );
            return {
              id: sys.id,
              imageUrl: fields.image.fields.file.url,
              name: fields.name,
              categories: categories,
            };
          });
          this.setState({
            categoriesName: rawCategoryName.fields.name,
            productData: {
              loading: false,
              error: null,
              products: products,
              total: rawProductData.total,
            },
          });
        })
        .catch((error) =>
          this.setState({
            product: {
              error: error,
            },
          })
        );
    }
  }

  render() {
    const {  products, total } = this.state.productData;
    console.log(this.state.categories);
    
    return (
      <React.Fragment>
        <Header logo="/assets/images/logo/image.png" />
        <PageHelmet pageTitle={this.state.categoriesName} />​
        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--150 pb--200 bg_image"
          style={{
            backgroundImage: `url("${this.state.content.imageUrl}")`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title pt--100">
                  <h2 className="theme-gradient mb-0">
                    {this.state.content.title} {this.state.categoriesName}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}
        {products.length===0?(
          <div className="container d-flex justify-content-center w-100 flex-row loading-container">
          <div class="spinner-grow mr-1 ml-1" role="status">
            <span class="sr-only">Loading...</span>
          </div>{" "}
          <div class="spinner-grow mr-1 ml-1" role="status">
            <span class="sr-only">Loading...</span>
          </div>{" "}
          <div class="spinner-grow mr-1 ml-1" role="status">
            <span class="sr-only">Loading...</span>
          </div>{" "}
        </div>
        ):(<></>)}
        <div className="rn-page-title-area pt--50 pb--100 bg_image row">
          <div className="container d-flex justify-content-between flex-wrap-reverse">
              
            {products.length > 0 ? (
              <div className="col-12 col-md-8">
                <div className="row">
                  {products.map((value, index) => {
                    return (
                      <div className="col-12 col-md-6 col-lg-4 m-auto" key={index}>
                        <Link
                          to={`/product/${value.id}`}
                          className="d-flex align-middle"
                        >
                          <Product product={value} cate={[...this.state.categories]}/>
                        </Link>
                      </div>
                    );
                  })}
                </div>
                <div className="row mt--20">
                  <div className="col-lg-12">
                    <Pagination
                      currentPage={this.state.currentPage}
                      setCurrentPage={(currentPage) => {
                        this.setState({
                          currentPage: currentPage,
                        });
                      }}
                      totalPage={Math.ceil(total / 4)}
                      limitPage={5}
                      style="pagination-vn"
                    />
                  </div>
                </div>
              </div>
            ) : (
             
                <>
                  
                </>
            )}
            <div className="col-12 col-md-4">
              <h3 className="text-left">{this.state.content.subTitle}</h3>
              <Categories
                categories={this.state.categories}
                selectedCategory={this.state.selectedCategory}
                setSelectedCategory={(selectedCategory) =>
                  this.setState({
                    selectedCategory: selectedCategory,
                  })
                }
                style="category-category"
              />
            </div>
          </div>
        </div>
        {/* <CallAction /> */}
        <FooterTwo />
      </React.Fragment>
    );
  }
}
export default withRouter(Category);