// React
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

// Create Import File
import "./index.scss";

import PageScrollTop from "./component/PageScrollTop";

// Home Page
import Home from "./home/Home";

// Element Page
import About from "./elements/About";
import Contact from "./elements/Contact";
import ExportToVn from "./elements/ExportToVN";
import ExportToAU from "./elements/ExportToAU";
import error404 from "./elements/error404";
import Search from "./elements/Search";
import Category from "./elements/Category";

// Service
import * as serviceWorker from "./serviceWorker";
import ProductDetail from "./elements/ProductDetails";

// Business Representative
import BusinessRepresentative from "./elements/BusinessRepresentative";
import BusinessRepresentativeDetail from "./elements/BusinessRepresentativeDetail";
// import BusinessRepresentativeDetail from "./elements/BusinessRepresentativeDetail";
import setToken from "./elements/SetToken";
import Logout from "./elements/logout";
import setLanguageToken from "./elements/setlanguage";
class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <PageScrollTop>
          <Switch>
            <Route path="/:path?">
              <Switch>
                <Route exact path="/contact" component={Contact} />
                <Route path="/search" component={Search} />
                <Route path="/category/:id" component={Category} />
                <Route path="/settoken/:token/:url" component={setToken} />
                <Route path="/removetoken/:url" component={Logout} />
                <Route
                  exact
                  path="/business-representative"
                  component={BusinessRepresentative}
                />
                <Route exact path="/about" component={About} />
                <Route exact path="/export-to-au" component={ExportToAU} />
                <Route exact path="/export-to-vn" component={ExportToVn} />
                <Route exact path="/" component={Home} />

                <Route
                  path="/business-representative/:id"
                  component={BusinessRepresentativeDetail}
                />
                <Route path="/product/:id" component={ProductDetail} />
                <Route path="/setlanguage/:url/:lang" component={setLanguageToken} />
              </Switch>
            </Route>
            <Route path="*">
              <Redirect to="/error/404" />
            </Route>
            <Route exact path="/error/404" component={error404} />
          </Switch>
        </PageScrollTop>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();