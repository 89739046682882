import { client } from '../../client';
export const GetHomePageContent = async () => {
     return client
    .getEntries({ include: '10', content_type: 'homePage', limit: 1, locale: localStorage.getItem('language') })
    .then((rawHomePage) => {
      const homePage = rawHomePage.items[0].fields;
      const carouselSlides = homePage.carouselSlide.map((slide) => {
        const { sys, fields } = slide;
        const { title = '', textPosition, image } = fields;
        return {
          id: sys.id,
          slideImageURL: image.fields.file.url,
          title: title,
          textPosition:
            textPosition === 'Text Left' ? 'text-left' : textPosition === 'Text Right' ? 'text-right' : 'text-center',
        };
      });
      const pageIntroduction = {
        id: homePage.pageIntroduction.sys.id,
        title: homePage.pageIntroduction.fields.title,
        subtitle: homePage.pageIntroduction.fields.subtitle,
        slides: homePage.pageIntroduction.fields.slides.map((slide) => {
          const { sys, fields } = slide;
          const { title, subtitle, link } = fields;
          return {
            id: sys.id,
            title: title,
            subtitle: subtitle,
            link: link,
          };
        }),
      };
      const exportToAustralia = {
        id: homePage.exportToAustralia.sys.id,
        imageUrl: homePage.exportToAustralia.fields.image.fields.file.url,
        exportProducts: homePage.exportToAustralia.fields.exportProducts.fields,
      };
      const exportToVietNam = {
        id: homePage.exportToVietNam.sys.id,
        imageUrl: homePage.exportToVietNam.fields.image.fields.file.url,
        exportProducts: homePage.exportToVietNam.fields.exportProducts.fields,
      };
      const businessRepresentative = {
        id: homePage.businessRepresentative.sys.id,
        title: homePage.businessRepresentative.fields.title,
        subtitle: homePage.businessRepresentative.fields.subtitle,
        background: homePage.businessRepresentative.fields.background.fields.file.url,
        businesses: homePage.businessRepresentative.fields.businesses?.map((business) => ({
          id: business.sys.id,
          name: business.fields.name,
          url: business.fields.logo.fields.file.url,
          summary: business.fields.summary,
        })),
      };
      const businessConsulting = {
        title: homePage.businessConsulting.fields.title,
        description: homePage.businessConsulting.fields.slogan,
        imageUrl: homePage.businessConsulting.fields.image.fields.file.url,
        features: homePage.businessConsulting.fields.features.map((feature) => {
          const { sys, fields } = feature;
          return {
            id: sys.id,
            title: fields.title,
            description: fields.description,
          };
        }),
      };
      const aboutUs = {
        title: homePage.aboutUs.fields.title,
        slogan: homePage.aboutUs.fields.slogan,
        imageUrl: homePage.aboutUs.fields.image.fields.file.url,
        description: homePage.aboutUs.fields.description,
      };
      const businessForum = {
        title: homePage.businessForum.fields.title,
        subtitle: homePage.businessForum.fields.subtitle,
      };
      let state = {
        content: {
          loading: false,
          pageIntroduction: pageIntroduction,
          exportToAustralia: exportToAustralia,
          exportToVietNam: exportToVietNam,
          businessRepresentative: businessRepresentative,
          businessConsulting: businessConsulting,
          aboutUs: aboutUs,
          businessForum: businessForum,
        },
        carouselSlides: carouselSlides,
      };
      console.log(state)
        return state;
    })
    .catch((error) => console.log(error));
};
