import React, { Component } from "react";
import BrandOne from "./Brand";
import { client } from "../../client";
import Header from "../component/header/CustomHeader";
import PageHelmet from "../component/common/Helmet";
import FooterTwo from "../component/footer/FooterTwo";


class BusinessRepresentative extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buReprePage: {
        loading: true,
        error: null,
        vietNamBusiness: {},
        australiaBusiness: {},
        headerImageUrl: {},
      },
    };
  }

  componentDidMount() {
    client
      .getEntries({ content_type: "businessRepresentativePage", include: 10, locale: localStorage.getItem("language") })
      .then((rawbuReprePage) => {
        const buReprePage = rawbuReprePage.items[0].fields;
        const vietNamBusiness = {
          title: buReprePage.vietNamBusiness.fields.title,
          subtitle: buReprePage.vietNamBusiness.fields.subtitle,
          backgroundImage: buReprePage.vietNamBusiness.fields.background.fields.file.url,
          businesses: buReprePage.vietNamBusiness.fields.businesses?.map(
            (business,key) => ({
              id: business.sys.id,
              key: key,
              name: business.fields.name,
              url: business.fields.logo.fields.file.url,
              summary: business.fields.summary,
            })
          ),
        };
        const australiaBusiness = {
          title: buReprePage.australiaBusiness.fields.title,
          subtitle: buReprePage.australiaBusiness.fields.subtitle,
          backgroundImage: buReprePage.australiaBusiness.fields.background.fields.file.url,
          businesses: buReprePage.australiaBusiness.fields.businesses?.map(
            (business,key) => ({
              id: business.sys.id,
              key: key,
              name: business.fields.name,
              url: business.fields.logo.fields.file.url,
              summary: business.fields.summary,
            })
          ),
        };
        const headerImageUrl = {
          id: buReprePage.headerImage.sys.id,
          url: buReprePage.headerImage.fields.file.url,
        };
        this.setState({
          buReprePage: {
            loading: false,
            vietNamBusiness: vietNamBusiness,
            australiaBusiness: australiaBusiness,
            headerImageUrl: headerImageUrl,
            advantage: buReprePage.businessRepresentativeAdvantage,
            introduction: buReprePage.introduction,
          },
        });
      })
      .catch((error) =>
        this.setState({
          buReprePage: {
            error,
          },
        })
      );
  }
  render() 
  {
    const buReprePage = this.state.buReprePage;
    return !buReprePage.loading && !buReprePage.error ? (
      <React.Fragment>
        <Header logo="/assets/images/logo/image.png" />
        <PageHelmet pageTitle="Business Representative" />
        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area mt--120 img__page_BRPage bg_image"
          style={{
            backgroundImage: `url("${this.state.buReprePage?.headerImageUrl?.url}")`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-12 p-0">
                <div className="rn-page-title text-center ">
                  <h2 className="title theme-gradient mb--30">
                    {localStorage.getItem("language") === "vi-VN"? "Đại Diện Kinh Doanh": "Business Representative"}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start About Area  */}
        
            <div className="container business__representative pt-4">
                <div className="row mb-4 my-xl-0">
                      <div className="col pt-4">
                        <p className="col-12 text-left text-xl-center m-auto">
                        {buReprePage.introduction.fields.text.content[0].content[0].value}
                        </p>
                        <div className="text-center text-xxl-left my-4">
                          <button className="text-light font-weight-bold text-uppercase px-4 py-2 rounded-pill" onClick={()=>{
                            return window.location.href = `${process.env.REACT_APP_SUB_DOMAIN}/users/login/${encodeURIComponent(window.location.href)}`;
                          }}>{buReprePage.introduction.fields.textButton}</button>
                        </div>
                      </div>
                </div>
                <div className="row">
                  {buReprePage.advantage.fields.description.map((item,key) => (
                      <div className={`${key%2===0?"row flex-column-reverse flex-xl-row-reverse my-sm-4 mb-xl-0 mx-0":"row flex-column-reverse flex-xl-row my-4 my-xl-0 mx-0"}`} key={key}>
                        <div className="col pt-5">
                          <h2 className="heading text-uppercase text-sm-center text-xl-left pl-xl-5">{item.fields.title}</h2>
                          <p className="col-12 col-sm-12 col-xl-10 text-xl-justify pl-xl-5">
                            {item.fields.description.content[0].content[0].value}
                          </p>
                        </div>
                        <div className="col m-auto" >
                            <div className="row align-items-center">
                                <div className="col text-center text-xl-left" style={{padding:0}}>
                                  <img src={item.fields.image.fields.file.url} alt="" />
                                </div>
                            </div>
                        </div>
                      </div>
                  ))}
                </div>   
            </div>
        {/* End About Area  */}


        <div className="rn-brand-area pb--20 bg_color--1">
          <div className="rn-about-area bg_color--5">
            {/* Start Brand Area  */}
            <div className="rn-brand-area pb--80 bg_color--5">
              <div className="section-title text-center service-style--3 mb--30 mb_sm--0 rn-page-title-area pt--40 pb--40 bg_image" 
                style={{
                      backgroundImage: `url("${buReprePage.vietNamBusiness.backgroundImage}")`,
                  }} >
                  <h2 className="title" style={{color:"white"}}>
                    {buReprePage.vietNamBusiness.title}
                  </h2>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    {buReprePage.australiaBusiness.businesses ? (
                      <BrandOne
                        branstyle="brand-list-vn branstyle--2"
                        listyle="brand-list-li-vn mt-3"
                        brands={buReprePage.vietNamBusiness.businesses}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {/* End Brand Area  */}

            {/* Start Brand Area  */}
            <div className="rn-brand-area pt--20 pb--50 bg_color--1">
            <div className="section-title text-center service-style--3 mb--30 mb_sm--0 rn-page-title-area pt--40 pb--40 bg_image" 
                style={{
                      backgroundImage: `url("${buReprePage.australiaBusiness.backgroundImage}")`,
                  }} >
                  <h2 className="title" style={{color:"white"}}>
                    {buReprePage.australiaBusiness.title}
                  </h2>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    {buReprePage.australiaBusiness.businesses ? (
                      <BrandOne
                        branstyle="brand-list-au branstyle--2"
                        listyle="brand-list-li-au mt-3"
                        brands={buReprePage.australiaBusiness.businesses}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {/* End Brand Area  */}
          </div>
        </div>
        {/* <CallAction /> */}
        <FooterTwo />
      </React.Fragment>
    ) : null;
  }
}
export default BusinessRepresentative;
