import React, { Component } from "react";
import Slider from "react-slick";
import { serviceSlick, serviceSlick1 } from "../page-demo/script";

import { getPosts } from "../../api/getPosts.js";

class ForumPostSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      posts: [],
    };
  }
  componentDidMount() {
    getPosts()
      .then((posts) => {
        this.setState({ posts: posts });
      })
      .catch((error) => {
        this.setState({ error: error });
      });
  }
  getColor=(id)=>{
    if (this.props.setColor)
    {
      if (id===0||(id===3)) return "#00A4E0";
      else if (id===1||(id===4))  return "#006BBD";
      else return "#095692";
    }
    else return "";
  }
  render() {
    const { item, column } = this.props;
    const isMobile = window.matchMedia("(max-width: 574px)");
    const serviceStyle = isMobile.matches ? serviceSlick1 : serviceSlick;
    return (
      <React.Fragment>
        <div className="row">
          
          <Slider className="rn-slick-dot dot-light" {...serviceStyle}>
            {this.state.posts.slice(0, item).map((post, i) => (
              <a href={`${process.env.REACT_APP_SUB_DOMAIN}/post/${post.id}`} key={i}>
                <div className={`${column}`} >
                  <div className="service service__style--2" style={{backgroundColor:`${this.getColor(i)}`}}>
                    <div className="content"> 
                      <h3 className="title text-capitalize service__title" style={{color:`${this.props.setColor?"white":""}`,fontSize:"20px"}}>{post.title}</h3>
                        <p className="service__desc summary_desc" style={{color:`${this.props.setColor?"white":""}`}}>{post.description}</p>
                    </div>
                  </div>
                </div>
              </a>
            ))}
          </Slider>
        </div>
      </React.Fragment>
    );
  }
}
export default ForumPostSlider;