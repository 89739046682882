import React, { Component } from "react";
import { client } from "../../client";
import Product from "./product/Product";
import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Link } from "react-router-dom";
import Zoom from "react-img-hover-zoom";
import FooterTwo from "../component/footer/FooterTwo";
import Header from "../component/header/CustomHeader";
import PageHelmet from "../component/common/Helmet";
class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "description",
      productData: [],
      relatedProductData: [],
      httpImageUrl: "",
      total: 0,
    };
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick(id) {
    this.setState({ selected: id });
  }

  componentDidMount() {
    // Get id params
    const MAXSIZE = 4;
    const id = this.props.match.params.id;
    // Get product data
    Promise.all([
      client.getEntry(id),
      client.getEntries({
        content_type: "product",
        "sys.id[ne]": id,
        limit: MAXSIZE,
      }),
      client.getEntries({
        content_type: "category",
      }),
    ]).then(([rawProduct, rawRelatedProducts, cate]) => {
      const { sys, fields } = rawProduct;
      const categories = fields.categories.map(
        (category) => category.fields.name
      );
      const allCategories = cate.items.flatMap((element)=>{
        return {
          name:element.fields.name,
          id:element.sys.id
        }
      })
      const productData = {
        id: sys.id,
        name: fields.name,
        sku: fields.sku,
        brand: fields.brand.fields.name,
        categories: categories,
        tag: fields.tag,
        imageUrl: fields.image.fields.file.url,
        description: fields.description,
        recipes: fields.recipes,
      };

      const relatedProductData = rawRelatedProducts.items.map((product) => {
        const { sys, fields } = product;
        const categories = fields.categories.map(
          (category) => category.fields.name
        );
        return {
          id: sys.id,
          imageUrl: fields.image.fields.file.url,
          name: fields.name,
          categories: categories,
        };
      });

      this.setState({
        productData,
        relatedProductData,
        httpImageUrl: `http:${productData.imageUrl}`,
        total: rawProduct.total,
        allCategories,
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.relatedProductData.length !== 0 &&
      this.state.relatedProductData.length !==
        prevState.relatedProductData.length
    ) {
      const MAXSIZE = 4;
      const id = this.props.match.params.id;
      const skip = Math.floor(
        Math.random() *
          (this.state.total > MAXSIZE ? this.state.total < MAXSIZE : 0)
      );
      client
        .getEntries({
          content_type: "product",
          "sys.id[ne]": id,
          limit: MAXSIZE,
          skip: skip,
        })
        .then((rawRelatedProducts) => {
          const relatedProductData = rawRelatedProducts.items.map((product) => {
            const { sys, fields } = product;
            const categories = fields.categories.map(
              (category) => category.fields.name
            );
            return {
              id: sys.id,
              imageUrl: fields.image.fields.file.url,
              name: fields.name,
              categories: categories,
            };
          });
          this.setState({
            relatedProductData,
          });
        });
    }
  }
  
  render() {
    const description = this.state.productData.description;
    const recipes = this.state.productData.recipes;
    const richTextOptions = {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
          <div className="image mb-2">
            <img
              src={node.data.target.fields.file.url}
              alt={node.data.target.fields.title}
            />
            <figcaption>{node.data.target.fields.description}</figcaption>
          </div>
        ),
        [BLOCKS.QUOTE]: (node, children) => <blockquote>{children}</blockquote>,
        [BLOCKS.TABLE]: (node, children) => (
          <table
            border={1}
            cellPadding={2}
            cellSpacing={2}
            style={{
              borderWidth: "0",
            }}
          >
            {children}
          </table>
        ),
        [BLOCKS.TABLE_ROW]: (node, children) => {
          if (
            children.every((node) => node.nodeType === BLOCKS.TABLE_HEADER_CELL)
          ) {
            // all children are header cells, so we should wrap the row
            // with a <thead /> tag
            return (
              <thead>
                <tr>{children}</tr>
              </thead>
            );
          } else {
            // not a header row, so we can render an ordinary <tr />
            return <tr>{children}</tr>;
          }
        },
        [BLOCKS.TABLE_CELL]: (node, children) => (
          <td className="pl-2 ptb-3" style={{ borderTop: "none" }}>
            {children}
          </td>
        ),
      },
    };
    return (
      <React.Fragment>
        <Header logo="/assets/images/logo/image.png" />
        <PageHelmet pageTitle="Product Detail" />
        <div className="rn-about-area pt--120 pb--50 bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6 col-12  d-flex justify-content-center">
                {/* <div className="thumbnail"> */}
                {this.state.httpImageUrl && (
                  <Zoom
                    img={this.state.httpImageUrl}
                    zoomScale={1.5}
                    height={450}
                    width={450}
                    transitionTime={0.5}
                  />
                )}
                {/* </div> */}
              </div>
              <div className="col-lg-6 col-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="mb-4" style={{ lineHeight: "3.5rem" }}>
                      {this.state.productData.name}
                    </h2>
                    <h4 className="fontWeight300 mb-1">
                      <b className="fontWeight500">Brand:</b> <span></span>
                      {this.state.productData.brand}
                    </h4>
                    <h4 className="fontWeight300 mb-1">
                      <b className="fontWeight500">Categories:</b> <span></span>
                      {this.state.productData.categories?.join(", ")}
                    </h4>
                    <h4 className="fontWeight300 mb-1">
                      <b className="fontWeight500">SKU:</b> <span></span>
                      {this.state.productData.sku}
                    </h4>
                    <h4 className="fontWeight300 mb-1">
                      <b className="fontWeight500">Tag:</b> <span></span>
                      {this.state.productData.tag}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <ul className="row align-items-center mt--30 pl-0">
              <li
                type="button"
                onClick={() => this.handleOnClick("description")}
                className={`btn col-lg-6 col-sm-2  ${
                  this.state.selected &&
                  (this.state.selected === "description" ? "btn-primary" : null)
                }`}
              >
                Description
              </li>
              <li
                type="button"
                onClick={() => this.handleOnClick("recipes")}
                className={`btn col-lg-6 col-sm-2 ${
                  this.state.selected &&
                  (this.state.selected === "recipes" ? "btn-primary" : null)
                }`}
              >
                Recipes
              </li>
              <span className="border-bottom border"> </span>
            </ul>
            <div className="row w-100 justify-content-between">
              {/* Description */}
              {this.state.selected === "description" ? (
                <div className="align-items-center mt--30 flex-1 col-12 col-md-6">
                  {description
                    ? documentToReactComponents(description, richTextOptions)
                    : null}
                </div>
              ) : (
                <div className=" align-items-center mt--30 col">
                  {recipes
                    ? documentToReactComponents(recipes, richTextOptions)
                    : null}
                </div>
              )}
              {/* Start Product Categories */}
              {/* <div className="mt--30 categories col-12 col-md-5">
                <h3 className="">PRODUCT CATEGORIES</h3>
                <ul className="">
                  {this.state.allCategories?.map((element, i) => (
                    <li key={i}>
                      <Link to={`/category/${element.id}`}>
                        <button>{element.name}</button>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div> */}
            </div>

            {/* Start Related Products */}
            <div className="row align-items-center mt--30">
              <div className="col-12">
                <b style={{ fontSize: "2rem" }}>Products you may like</b>
              </div>
              <div className="portfolio-area bg_color--5">
                <div className="portfolio-sacousel-inner">
                  <div className="container">
                    <div className="row">
                      {this.state.relatedProductData?.map((value, index) => {
                        return (
                          <div
                            className="col-lg-3 col-md-6 col-12"
                            key={index}
                            onClick={() => this.handleOnClick()}
                          >
                            <a href={`/product/${value.id}`}>
                              <Product product={value} cate={this.state.allCategories} />
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Related Products */}
          </div>
        </div>
        {/* <CallAction /> */}
        <FooterTwo />
      </React.Fragment>
    );
  }
}
export default ProductDetail;
