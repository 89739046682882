import React from 'react';
import BrandOne from '../elements/Brand';
function Heading(props) {
  const { title, subtitle, background } = props;
  return (
    <div
      className="section-title text-center service-style--3 mb--30 mb_sm--0 rn-page-title-area pt--40 pb--40 bg_image"
      style={{
        backgroundImage: `url("${background}")`,
      }}
    >
      <h2 className="title newTitle text-uppercase" style={{ color: 'white' }}>
        {title}
      </h2>
      <p className="newSubtitle text-uppercase" style={{ color: 'white' }}>{subtitle}</p>
    </div>
  );
}
function Brands(props) {
  const { businesses } = props;
  return (
    <div className="container-xl">
      <div className="row">
        <div className="col-lg-12">
          {businesses ? (
            <BrandOne
              branstyle="brand-list branstyle--2"
              listyle="brand-list-li-au mt-3"
              setColor={true}
              brands={businesses}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
function BusinessRepresentative(props) {
  const {content: { background, title, subtitle, businesses }} = props;
  return (
    <div className="rn-brand-area pt--30 pb--80 bg_color--1">
      <Heading title={title} subtitle={subtitle} background={background} />
      <Brands businesses={businesses} />
    </div>
  );
}
export default BusinessRepresentative;
