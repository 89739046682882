import React, { Component } from "react";

class setLanguageToken extends Component
{
  componentDidMount()
  {
    localStorage.removeItem("language");
    const { url,lang } = this.props.match.params;
    localStorage.setItem("language",lang);
    const URL=decodeURIComponent(url);
    window.location.href = URL;
  }
  render() 
  {
    return (<div className=""></div>)
  }
}
export default setLanguageToken;
