import React from 'react';
function Slide({children}) {
    return (  
        <div className="row creative-service">
            <div className="col-lg-12">
                {children}
            </div>
        </div>
    );
}

export default Slide;