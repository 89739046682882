import React, { Component } from "react";
class setToken extends Component {
  componentDidMount(){
    const { token,url } = this.props.match.params;
    localStorage.setItem("token", token);
    const URL=decodeURIComponent(url);
    window.location.href = URL;
  }
  render() 
  {
    return (
      <div className="">
      </div>
    );
  }
}
export default setToken;
