import React, { Component } from "react";
import { Card } from "react-bootstrap";

function ForgotMpin({ to = "", content = "" }) {
  return (
    <div
      className="product-cate"
      onClick={(e) => {
        e.preventDefault();
        window.location.href = to;
      }}
    >
      {content}
    </div>
  );
}
class Product extends Component {
  render() {
    const { product, cate = [] } = this.props;
    return (
      <React.Fragment>
        <div className={`portfolio`}>
          <Card className="product">
              <Card.Img
                className="img-product"
                variant="top"
                src={product.imageUrl}
                alt="Product Image"
              />
            <Card.Body className="pb-1">
              <Card.Title>{product.name}</Card.Title>
                {product.categories?.map((item) => {
                  const res = cate.filter((cate) => {
                    return cate.name === item;
                  });
                  return (
                    <p key={res[0]?.id} style={{color:"red"}}>{res[0]?.name}</p>
                  );
                })}
            </Card.Body>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}
export default Product;
